import React from 'react'
import '../css/digital-usecase.scss'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import { Link } from 'gatsby'
import JourneyMapImg from '../images/digital-usecase/delware-tech/journey-map.png'
import { FaAngleLeft } from "react-icons/fa";

const DelwareTech = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="px-5 pt-4">
                <Link to="/digital-usecase"><p className="cursor black-link-text mb-0 my-auto"><span className="mr-1"><FaAngleLeft /></span>Digital</p></Link>
            </div>

            <div className="digital-usecase pb-5 pt-4">
                <div className="bg-delware"></div>
            </div>


            <div className="container">
                <h1 className="font-weight-bold text-center mt-md-5 big-text">Designing a digital transformation road map</h1>

                <p className="text-center mt-4 mb-4 pb-2 text-uppercase inner-page-breadcrumb"><span className="text-secondary mr-2">Digital strategy</span>consultancy</p>

                <div style={{ borderBottom: '5px solid #E41700' }}></div>
            </div>

            <div className="py-5 my-5"></div>

            <div className="bg-client-usecase">
                <div className="container">
                    <div className="row pt-5 mt-5 pb-5">
                        <div className="col-md-6 my-5">
                            <p className="font-weight-bold" style={{fontSize: '1.5rem'}}>The Client</p>

                            <p className="text-secondary" style={{fontSize: '1.15rem', lineHeight: '30px'}}>Our client, a top consulting company in Nigeria, sought our expertise to design a digital transformation roadmap that would give them a competitive advantage in the digital economy.</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-solution-usecase">
                <div className="container">
                    <div className="row pt-5 mt-5 pb-5">
                        <div className="col-md-6 mx-auto my-5">
                            <p className="font-weight-bold" style={{fontSize: '1.5rem'}}>Our Solution</p>

                            <p className="text-secondary" style={{fontSize: '1.15rem', lineHeight: '30px'}}>First, we assessed their digital footprints and platforms like social media pages and websites. Next, we drew up a winning digital strategy in line with the big goals of the organization. Finally, we implemented this strategy across value chains, helping them transition into a successful digital business.</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row pt-5 mt-5">
                    <div className="col-md-6 mx-auto">
                        <img src={JourneyMapImg} alt="Journey Map" className="img-fluid" />
                        <p className="text-secondary text-center mt-1">Training the staff on must-knows of organisational success</p>
                    </div>
                </div>
            </div>

            <div className="my-5 py-5"></div>

            <div className="bg-impact-usecase">
                <div className="container">
                    <div className="row pt-5 mt-5">
                        <div className="col-md-6 my-5">
                            <p style={{fontSize: '1.5rem'}} className="font-weight-bold">The Impact</p>

                            <ul>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Equipped the organization to thrive in the digital economy.</p></li>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Transformed their business offerings for positive returns.</p></li>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Revamped customer journey and experience for better service delivery.</p></li>
                                <li><p style={{fontSize: '1.15rem', lineHeight: '30px'}} className="text-secondary mb-1">Boosted overall return on investment (ROI)</p></li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row py-5 my-5">
                    <div className="col-md-5 mx-auto mb-5 text-center">
                        <Link to={`/contact`} state={{ path: 'digital' }}><button style={{height: '48px'}} class="btn btn-red h-100 btn-red-black px-4 no-border-radius">Get started</button></Link>
                    </div>
                </div>
            </div>
        </Layout >
    )
}

export default DelwareTech
